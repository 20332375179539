<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-toolbar-title>Customer Information</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="formClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-list three-line subheader>
            <v-expansion-panels>
                <v-expansion-panel v-for="user in userInfo" :key="user.hotelCustomerInformationID">
                    <v-expansion-panel-header
                            class="subtitle-1 text-capitalize"
                    >{{user.firstName}} {{user.lastName}}
                    </v-expansion-panel-header> 
                    <v-expansion-panel-content>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <td class="font-weight-bold">Countery / State</td>
                                    <td>{{user.country}} / {{user.state}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Gender</td>
                                    <td>{{user.gender}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Age</td>
                                    <td>{{user.age}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">CitizenShip Number</td>
                                    <td>{{user.citizenShipNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Passport Number</td>
                                    <td>{{user.passportNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">License Number</td>
                                    <td>{{user.licenseNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Document</td>
                                    <td>
                                        <v-img :src="user.document" height="10"></v-img>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-list>
    </v-card>
</template>

<script>
    /*import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";

    export default {
        name: "editAdminForm",
        props: ["hotelCustomerID", "pendingData"],
        watch: {
            dialog: {
                handler: function (val) {
                    console.log("watch", this.dialog, val);
                    if (this.dialog == false) {
                        this.$emit("formResponse", val);
                    }
                    this.dialog = true;
                },
                deep: true
            }
        },
        data() {
            return {
                dialog: true,
                notifications: false,
                sound: true,
                widgets: false,
                userInfo: {},
                rooms: []
            };
        },
        mounted() {
            this.formData();
        },
        methods: {
            formClose(val) {
                console.log(val)
                this.$emit("formResponseClose", val);
            },
            async formData() {
                let response = await axios.post(
                    "Hotel/GetHotelCustomerInformationListAsync",
                    {HotelCustomerID: this.hotelCustomerID}
                );
                this.userInfo = response.data.data;
                console.log('this.userInfo :', this.userInfo)
            }
        }
    };
</script>

<style scoped>
</style>
